import {
  API_DATE_FORMAT,
  parseDate,
  PeriodPicker,
} from "@flixbus-phx/marketplace-common";
import { format } from "date-fns";
import * as React from "react";
import { useIntl } from "react-intl";
import useRideFilter, {
  rideFilterVar,
} from "../../../../state/useRideFilter/useRideFilter";
import { endDate, startDate } from "../../helpers/calendarBoundaries/calendarBoundaries";
import getWeekdaysOfPeriod from "../../helpers/getWeekdaysOfPeriod/getWeekdaysOfPeriod";

const PeriodFilter: React.FC = () => {
  const [rideFilter, , setPeriodAndRideFilter] = useRideFilter(rideFilterVar);
  const { formatMessage } = useIntl();

  const handlePeriodChange = (start: Date, end: Date) => {
    const formatedStart = format(start, API_DATE_FORMAT);
    const formatedEnd = format(end, API_DATE_FORMAT);

    const weekdaysOfPeriod = getWeekdaysOfPeriod(start, end);
    const departureDays = rideFilter.departureDays.filter((d) =>
      weekdaysOfPeriod.includes(d.toLowerCase())
    );

    setPeriodAndRideFilter({
      ...rideFilter,
      departureDays,
      period: { startDate: formatedStart, endDate: formatedEnd },
    });
  };

  return (
    <PeriodPicker
      inputElement="input"
      longestSelectablePeriodInDays={31}
      earliestStartDate={startDate}
      latestEndDate={endDate}
      label={formatMessage({ id: "bookingInfo.filter.period.label" })}
      periodStart={parseDate(rideFilter.period.startDate)}
      periodEnd={parseDate(rideFilter.period.endDate)}
      onChange={handlePeriodChange}
    />
  );
};

export default PeriodFilter;
